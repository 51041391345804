@import '../../static/styles/variables';

.named-header {
  /*width: 225px;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: lato;

  .name {
    text-transform: uppercase;
    color: black;
    font-size: 30px;
  } 

  .title {
    color: $title-color;
    font-size: 16px;
  }


  div {
    z-index: 1;
  }

  svg {
    position: absolute;
    z-index: 0;
  }

  // .svg-circle {
  //   cx: 56;
  //   cy: 56;
  //   r: 56;
  //   stroke: white;
  //   stroke-width: 2;
  //   fill: transparent;
  // }

  // .svg-group {
  //   height: 112;
  //   width: 112;
  // }

  .svg-circle {
    cx: 56;
    cy: 56;
    r: 56;
    stroke: white;
    stroke-width: 2;
    fill: white;
  }

  .svg-rect {
    // x: 50;
    // y: 20;
    // width: 100%;
    // height: auto;
    // fill: white;

    // x: 5%;
    // width: 95%;
    // height: auto;
    // fill: white;

    x: 5%;
    y: 50%;
    width: 95%;
    height: 35%;
    fill: $main-bkg;
  }
}

