@import '../../static/styles/colors';
@import '../../static/styles/variables';

.bullet-list {
  font-size: 10px;
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: 10px;
  -webkit-margin-before: 0px;
  -webkit-padding-start: 0px;
}

.li-padding {
  padding-bottom: 5px;
}
.li-padding-left {
  padding-left: 5px;
}

.bullet-icon-circle {
  height: 8px;
  width: 8px;
  margin-right: 5px;
  border: 1px solid $alternate-color;
  border-radius: 50%;
  display: inline-block;
}

.bullet-icon-circle-small {
  height: 5px;
  min-height: 5px;
  width: 5px;
  min-width: 5px;
  margin-top: 3px;
  margin-right: 5px;
  border: .7px solid $main-color;
  border-radius: 50%;
  display: inline-block;
}

.bullet-icon-square {
  height: 6px;
  width: 6px;
  margin-right: 5px;
  border: 1px solid $alternate-color;
  background-color: $main-color;
  display: inline-block;
  vertical-align: middle;
  line-height: 13px;
}

.bullet-icon-triangle {
  width: 0;
  height: 0;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 5px solid $alternate-color;
  font-size: 0px;
  top: -4px;
  position: relative;
  margin-right: 5px;
}

.two-cols {
  list-style: none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.li-border {
  border-right: 1px solid $alternate-text-color;
}

.detailed-bullet-list {
  text-transform: uppercase;
  margin-left: 32px;  /* this is due to the text-indent below */
  -webkit-margin-before: 0px;
  -webkit-padding-start: 0px;
  margin-bottom: 6px;

  li {
    list-style-position: inside;
    text-indent: -12px;
    font-weight: 300;
  }
}

.detailed-bullet-title {
  font-size: 12px;
  margin-left: 20px;
  font-weight: 600;
  border-bottom: 1px solid $alternate-text-color;
}

.bullet-block {
  font-size: 10px;

  .date:before {
    content: "\2022";
    color: $alternate-text-color;
    margin: 0px 8px;
  }

  .date {
    text-transform: uppercase;
    font-weight: 300;
    color: $alternate-color;
  }
}

.padded-list {
  padding-bottom: 3px;
}

.detailed-bullet-group {
  margin-bottom: 20px;
}

.subtext {
  margin-left: 13px;
  font-size: 9px;
}