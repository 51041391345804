@import './static/styles/variables';

html {
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
}

@page{
  size: letter portrait;
  margin: 0;
}

*{
  box-sizing: border-box;
}

:root{
   --decorator-horizontal-margin: 0.2in;


  /* XXX: using px for very good precision control */
  --decorator-outer-offset-top: 10px;
  --decorator-outer-offset-left: -5.5px;
  --decorator-border-width: 1px;
  --decorator-outer-dim: 9px;
  --decorator-border: 1px solid #ccc;

  --row-blocks-padding-top: 5pt;
  --date-block-width: 0.6in;

  --main-blocks-title-icon-offset-left: -19pt;
}

body{
  width: $page-width;
  height: $page-height;
  margin: 0;
  font-family: "Open Sans", sans-serif; 
  font-weight: 300;
  line-height: 1.3;
  color: $main-text-color;
  hyphens: auto;
  background-color: $main-bkg;
  display: flex;
  flex-direction: column;
}

h1, h2, h3{
  margin: 0;
  color: #000;
}

li{
  list-style-type: none;
}


.content {
  display: flex;
  flex: 1;
  min-height: $content-height;

  .main {
    width: $main-width;
    padding: 15px $main-content-padding 0px $main-content-padding;
    flex: 1;
  }

  .sidebar {
    flex: 0 0 $sidebar-width;
    order: -1;
    background-color: $sidebar-bkg;
    padding: 0.5in 0.15in 0px $sidebar-horizontal-padding;

    .sidebar-content {
      padding-top: 50px;

      .objective-text {
        font-size: 12px;
        margin-left: 20px;
        margin-bottom: 16px;
      }
    }
  }
}


.flip-side {
  position: relative;
  left: 5.9in;
  font-family: "Open Sans", sans-serif; 
  font-size: 10px;
  font-weight: 300;
  line-height: 1.3;
  color: $alternate-color;
  text-decoration: none;
}



