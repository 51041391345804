@import '../../static/styles/variables';

.big-headings {
  display: flex;
  margin-bottom: 5px;
}
.bh-icon-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid white;
  z-index: 2;
  margin-top: -5px;
  margin-left: -5px;
  background-color: $alternate-color;
}
.bh-icon {
  color: white;
  margin-top: 6px;
  /*margin-left: 1px;*/
  font-size: 24px;
  text-align: center;
}

.bh-component {
  display: flex;
  background-color: $main-color;
  margin-left: -10px;
  padding-left: 20px;
  margin-top: 3px;
  width: 100%;
  max-height: 32px;
}
.bh-description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.tight-heading {
  margin-bottom: 0px;
}