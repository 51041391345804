/*
    Colors named using http://chir.ag/projects/name-that-color/
*/

/******************************
    Monochrome, Greys
*******************************/
  $gallery:             #eee;
  $alto:                #ddd;
  $silver:              #ccc;
  $mine-shaft:          #292828;
  $emperor:             #555555;
  $alabaster:           #fcfcfc;   /* could be f9f9f9 */

  $medium-light-gray:   #999;
  $midnight-gray:       #444;




/******************************
    Blues and Purples
*******************************/
  $danube:              #6C99C6;
  $picton-blue:         #328FEC;
  $san-marino:          #4073A5;
  $razzle-dazzle-rose:  #EE49E7;
  $medium-purple:       #A149EE;
  $heliotrope:          #B35FFC;
  $mulled-wine:         #4A4A6E;
  $deep-cerulean:       #006bb5;
  $heather:             #bcc8d3;
  $hoki:                #6886A1;

  $steel-blue:          #4F81BD;

  $waikawa-gray:        #5D77A4;
  $casper:              #AEC1D2;
  $pigeon-post:         #b5c8e2;
  $periwinkle-grey:     #CCDBEA;
  $havelock-blue:       #5885d4;

  $medium-dark-cyan-blue: #334858;
  

  //https://coolors.co/465459-80c0d6-e0f5fc-5acef4-26596b
  $dark-slate-gray:     #26596B;
  $dark-sky-blue:       #80C0D6;
  $light-cyan:          #E0F5FC;
  $columbia-blue:       #ccdfe6;


/******************************
    Reds and Oranges
*******************************/
  $rouge:               #A5406F;
  $burnt-sienna:        #EE5149;
  $carnation:           #F15962;

/******************************
    Greens and Yellows
*******************************/
  $shamrock:            #33CC99;
  $yellow:              #ffff00;

/******************************
    Standard Colors
*******************************/
  $black:               #000;
  $white:               #fff;


/*
  #e0e8cc  &   #00798c

  #456377   &  #eeddbc*/