@import '../../static/styles/variables';

.key-certification {
  margin-left: 20px;
  margin-bottom: 10px;

  .cert-heading {
    font-family: 'Roboto', sans-serif;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 4px;
    display: flex;
    align-items: center;

    .cert-image {
      display: flex;
      flex-direction: column;
      padding-right: 10px;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      /*opacity: .5;*/
    }

    .key-cert-text {
      display: flex;
      flex-direction: column;

      .key-cert-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
      }
      /*.key-cert-title:after {
        content: "\2022";
        color: $alternate-color;
        margin: 0px 8px;
      }*/

      .key-cert-date {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 300;
        color: $alternate-text-color;
      }

      .key-cert-sub-text {
        color: $alternate-text-color;
        font-size: 9px;  
      }
    }
  }
}