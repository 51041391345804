@import '../../static/styles/variables';

footer {
  background-color: $main-color;
  min-height: $footer-height;
  border-top: 4px solid $footer-border;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .footer-line {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .footer-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 9px;
    }

    .footer-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .footer-icon-circle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid #eee;
        z-index: 2;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .footer-icon {
        color: #000;
        font-size: 14px;
        text-align: center;
      }

      .footer-title {
        margin-left: 6px;
        margin-top: 1px;
        font-size: 12px;
        width: 145px;
        font-weight: 400;

        .footer-anchor {
          text-decoration: none;
          color: $main-text-color;
        }
      }
    }
  }
}

.curl-container {
  text-decoration: none;
  position: absolute;
  bottom: 0px - 37px;
  right: calc(100vw / 2 - 4.25in);
}
.curl-container:hover {
  .curl-text {
    transform: rotate(-45deg) scale(1.25) translateX(10px);
  }
}
.curl-text {
  font-size: 10px;
  transition: all .2s ease-in-out;
  color: #333;
  
  /* Safari */
  -webkit-transform: rotate(-45deg);
  /* Firefox */
  -moz-transform: rotate(-45deg);
  /* IE */
  -ms-transform: rotate(-45deg);
  /* Opera */
  -o-transform: rotate(-45deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

}
.curl {
  width:50px;
  height:50px;
  /*position: absolute;*/
  bottom:0;
  right:0;
  background : 
    linear-gradient(
      315deg, 
      #f4f4f4, 
      #f3f3f3 45%, 
      #ddd 50%, 
      #aaa 50%, 
      #bbb 56%, 
      #ccc 62%, 
      #f3f3f3 80%,
      #f4f4f4 100%
    );
  /*box-shadow : 0 0 10px rgba(0, 0, 0, .5);*/
  transition: all .5s ease;
}
.curl:before,
.curl:after {
  content: '';
  /*position: absolute;*/
  z-index: -1;
  left: 12.5%;
  bottom: 5.8%;
  width: 70%;
  max-width: 300px;
  max-height: 100px;
  height: 55%;
  box-shadow: 0 12px 15px rgba(0, 0, 0, .3);
  transform: skew(-10deg) rotate(-6deg);
}
.curl:after {
/*  left: auto;
  right: 5.8%;
  bottom: auto;
  top: 14.16%;
  transform: skew(-15deg) rotate(-84deg);*/
}

.curl:hover {
  width: 80px;
  height: 80px;
}
.curl:hover:before,
.curl:hover:after {
  box-shadow: 0 24px 30px rgba(0, 0, 0, .3);
}