@import '../../static/styles/variables';

.dot-bar-grouping {
  display: flex;
  flex-direction: column;
  padding-left: 10px;

  .dot-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .dot-bar {
      padding:5px 0px 0px 0px;
      width: 185px;

      .dot-title {
        font-family: 'Roboto', sans-serif;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 300;
        //letter-spacing: 1px;
      }
    
      .dots {
        .dot {
          height: 10px;
          width: 20px;
          margin-right: 0px;
          border: 1px solid $alternate-color;
          border-right: .5px solid $alternate-color;
          border-left: .5px solid $alternate-color;
          //border-radius: 50%;
          display: inline-block;
          transform: skew(-20deg);

          &.filled {
            background: $main-color;
          }
        }

        .legend {
          display: inline-block;
          font-size: 9px;
          vertical-align: middle;
        }
      }

      &.middle-col {
        width: 220px;
      }

      &.last-col {
        width: 120px;
      }
    }
  }

  .dot-bar-legend {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 300;
    //letter-spacing: 1px;
    transform: skew(-20deg);

    .dot-bar-legend-header {
      border: 1px solid $alternate-color;
      border-right: .5px solid $alternate-color;
      border-left: .5px solid $alternate-color;
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 800;
    }
    .dot-bar-legend-item {
      border: 1px solid $alternate-color;
      border-right: .5px solid $alternate-color;
      border-left: .5px solid $alternate-color;
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

}