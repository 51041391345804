@import './colors';

/***************
  Spacing
***************/

$page-width: 8.5in;
$main-width: 5.75in;
$sidebar-width: calc(#{$page-width} - #{$main-width});


$page-height: 11in;
$footer-height: calc(1in - 4px);
$content-height: calc(#{$page-height} - #{$footer-height});

:export {
  sidebarWidth: $sidebar-width;
}


/***************
  Padding
***************/

$sidebar-horizontal-padding: 0.2in;
$main-content-padding: 0.15in;



/***************
  Coloring
***************/

// $main-bkg: $white;

// $sidebar-bkg: $alto;
// //$sidebar-bkg: $medium-dark-cyan-blue;
// $sidebar-bkg: $columbia-blue;

// $main-color: $pigeon-post;
// //$main-color: $medium-purple;
// $main-color: $dark-sky-blue;

// $alternate-color: $havelock-blue;
// $alternate-color: $dark-slate-gray;
// //$alternate-color: $burnt-sienna;

// $footer-border: $periwinkle-grey;
// //$footer-border: $shamrock;
// $footer-border: $light-cyan;

// $title-color: $steel-blue;
// //$title-color: $razzle-dazzle-rose;
// $title-color: $dark-slate-gray;

// $main-text-color: $midnight-gray;
// //$main-text-color: $yellow;

// $alternate-text-color: $medium-light-gray;
// //$alternate-text-color: $picton-blue;


// //option 1
// $main-bkg: $white;

// $sidebar-bkg: $columbia-blue;

// $main-color: $dark-sky-blue;

// $alternate-color: $dark-slate-gray;

// $footer-border: $light-cyan;

// $title-color: $dark-slate-gray;

// $main-text-color: $midnight-gray;

// $alternate-text-color: $medium-light-gray;
// $alternate-text-color: #828282;


//option 2
$main-bkg: $white;

$sidebar-bkg: $columbia-blue;
//$sidebar-bkg: #9bccff;
//$sidebar-bkg: #A8CAC3;
$sidebar-bkg: #DDE8E9;

$main-color: $dark-sky-blue;
//$main-color: #9bccff;
$main-color: #468189;
$main-color: #77ACA2;
$main-color: #A8CAC3;
$main-color: #9ABABE;

$alternate-color: $dark-slate-gray;
$alternate-color: #104F55;
$alternate-color: #517F83;

$footer-border: $light-cyan;

$title-color: $dark-slate-gray;

$main-text-color: $midnight-gray;

//$alternate-text-color: $medium-light-gray;
$alternate-text-color: #828282;





/***************
  Typography
***************/
